<style>
    .titleH {
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        height: 248px;
        color: #ffffff;
        background-image: url("../../assets/bg002.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .lefttitle {
        font-size: 12px;
        width: 80px;
        text-align: right;
        letter-spacing: 2px;
        margin-top: 8px;
    }

    .rtitle {
        font-size: 12px;
        margin-top: 8px;

    }

    .lrtitle {

        font-size: 12px;
        margin-top: 5px;
    }

    .lrtitle1 {
        width: 80px;
        text-align: right;
        letter-spacing: 2px;
    }

    .line {
        display: flex;
    }

    body {
        background-color: #EFF0F3;
    }
</style>
<template>

    <div v-if="wxShow" style="display: flex;flex-direction:column;align-items: center;justify-content: center;background-color: #EFF0F3;">
        <div class="titleH">
            <div style="margin-top: 22px">
                <img style="width: 48px;height: 48px" src="../../assets/user.png">
            </div>
            <div style="font-size: 14px;color: white;font-weight: bold;margin-top: 12px">陕西省首届密码技术竞赛</div>
            <div style="height: 2px;width: 90%;background-color:#FFFFFF;margin-top: 12px;opacity: 0.65;margin-bottom: 10px "></div>
            <div style="display: flex;width: 90%;">
                <div class="line" style="width: 45%">
                    <div class="lefttitle">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</div>
                    <div class="rtitle">{{user.name}}</div>
                </div>
                <div class="line" style="width: 50%;margin-left: 5%">
                    <div class="lefttitle">手机号：</div>
                    <div class="rtitle">{{user.phone}}</div>
                </div>
            </div>
            <div class="lrtitle line" style="width: 90%">
                <div class="lrtitle1">身份证号：</div>
                <div class="lrtitle2" style="width: 70%">{{user.id_card}}</div>
            </div>
            <div class="lrtitle line" style="width: 90%">
                <div class="lrtitle1">考试赛道：</div>
                <div class="lrtitle2" style="width: 70%">{{user.type}}</div>
            </div>
            <!--  <div class="lrtitle line" style="width: 90%">
                  <div class="lrtitle1">团队名称：</div>
                  <div class="lrtitle2" style="width: 70%">{{user.tname}}</div>
              </div>-->

            <div class="lrtitle line" style="width: 90%">
                <div class="lrtitle1">单位名称：</div>
                <div class="lrtitle2" style="width: 70%" v-if="user.company_id>0">{{user.company.name}}</div>
                <div class="lrtitle2" style="width: 70%" v-else>{{user.company}}</div>
            </div>
        </div>

        <div v-if="user.examDesc&&user.examDesc.length>2" style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;color: #265599;font-weight: bold;display: flex;
align-items: center;justify-content: center">
            {{user.examDesc}}
        </div>

        <div v-if="user.check==1"
             style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;display: flex;align-items: center;justify-content: center">
            <div>
                <img style="width: 18px" src="../../assets/icon_dd.png">
            </div>
            <div style="margin-left:10px;font-weight: bold;color: #666666;font-size: 14px ">等待审核</div>
        </div>
        <div v-if="user.check==2"
             style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;display: flex;align-items: center;justify-content: center">
            <div>
                <img style="width: 18px" src="../../assets/icon_sb.png">
            </div>
            <div style="margin-left:10px;font-weight: bold;color: #DB4243;font-size: 14px ">审核失败</div>
        </div>
        <div v-if="user.check=='0'"
             style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;display: flex;align-items: center;justify-content: center">
            <div>
                <img style="width: 18px" src="../../assets/icon_suc.png">
            </div>
            <div style="margin-left:10px;font-weight: bold;color: #13B38F;;font-size: 14px ">审核成功</div>
        </div>

        <div v-if="user.check=='0'&&user.examStart==1&&user.examStatus=='-1'" @click="exam()"
             style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;display: flex;align-items: center;justify-content: center">
            <div style="margin-left:10px;font-weight: bold;color: #666666;font-size: 14px ">开始答题</div>
        </div>

        <div v-if="user.check=='0'&&user.examStart==1&&user.examStatus=='0'" @click="exam()"
             style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;display: flex;align-items: center;justify-content: center">
            <div style="margin-left:10px;font-weight: bold;color: #666666;font-size: 14px ">继续答题</div>
        </div>

        <div v-if="user.check=='0'&&user.examStart==1&&user.examStatus=='1'"
             style="border-radius: 6px;height: 50px;margin-top: 10px;background-color: white;width: 98%;display: flex;align-items: center;justify-content: center">
            <div style="margin-left:10px;font-weight: bold;color: #666666;font-size: 14px ">已交卷，请等待公布成绩</div>
        </div>

        <div @click="logout" style="position: absolute;bottom: 0;left: 0;display: flex;align-items: center;justify-content: center;width: 100%;
        background-color: white;height: 50px;font-size: 14px;font-weight: bold;color: #333333">
            退出系统
        </div>

        <el-dialog title="修改密码" :visible.sync="pwdShow" width="90%" :close-on-click-modal="false" :show-close="false">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="请输入新密码"  prop="pwd" >
                    <el-input v-model="form.pwd" autocomplete="off" show-password></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form">
                <el-form-item label="请再次输入新密码(数字大小写和特殊符号)"  prop="rpwd" >
                    <el-input v-model="form.rpwd" autocomplete="off" show-password></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="upwd('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import request from "../../plugins/axios.js";

    export default {
        data() {
            let that = this
            //密码
            let checkPwd = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入密码'));
                }

                let pwd = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/
                if (!pwd.test(value)) {
                    callback(new Error('密码需至少包含数字、字母、符号中的2种'));
                }
                if (value.length < 8) {
                    callback(new Error('密码长度应不低于8位'));
                }
                callback();
            };
            //二次密码验证
            let checkPwd2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== that.login_data.pwd) {
                    callback(new Error('两次输入密码不一致!'));
                }
                callback();
            };
            return {
                user: {
                    examStatus: -1
                },
                wxShow: false,
                pwdShow: false,
                form: {
                    pwd: '',
                    rpwd: ''
                },
                rules: {
                    pwd: [
                        {validator: checkPwd, trigger: 'blur'},
                    ],
                    rpwd: [
                        {validator: checkPwd2, trigger: 'blur'},
                    ],
                }
            };
        },
        components: {},
        mounted() {
            document.title ="陕西省首届密码技术竞赛"
            let that = this
            //设置背景动画播放速度
            let u = localStorage.getItem('u')
            if (u) {
                this.detail();
            } else {
                this.$router.push("/login");
            }

            var userAgent = navigator.userAgent.toLowerCase();
            // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
                that.wxShow = true;
            } else {
                that.$message.error('请在微信中打开');
                that.wxShow = false;
                return false
            }

        },
        methods: {
            //生成试卷准备答题
            exam() {
                let that = this
                let loadding = that.$loading({
                    lock: true,
                    text: '试题生成中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                request({
                    url: "/mn/e/ut",
                    data: {},
                }).then((ret) => {
                    loadding.close()
                    if (ret.code == 200) {
                        let exam_data = ret.data
                        let u = JSON.parse(localStorage.getItem('u'))
                        localStorage.setItem('exam_time', ret.message)
                        let token = u.token
                        let exam_data_st = JSON.parse(localStorage.getItem('exam_data' + token))

                        if (exam_data_st) {
                            if (exam_data_st.length < 4) {
                                localStorage.setItem('exam_data' + token, JSON.stringify(exam_data))
                            }
                        } else {
                            localStorage.setItem('exam_data' + token, JSON.stringify(exam_data))
                        }

                        this.$router.push("/answer");
                    } else {
                        that.$notify.error(ret.message);
                    }

                })

            },
            logout() {
                localStorage.clear();
                this.$router.push("/login");
            },
            //修改密码
            upwd(formName) {
                let that = this
                let form = that.form


                let login_data = that.login_data

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        request({
                            url: "/mn/up",
                            data: {
                                pwd: form.pwd
                            },
                        }).then((ret) => {
                            if (ret.code == 200) {
                                that.$notify.success(ret.message);
                                this.$router.push("/login");
                            } else {
                                that.$notify.error(ret.message);
                            }

                        })
                    } else {
                        console.log('error submit!!');
                    }
                });



            },
            detail() {
                let that = this
                request({
                    url: "/mn/de",
                    data: {},
                }).then((ret) => {
                    if (ret.code == 200) {
                        that.user = ret.data
                        if (ret.data.pwd == ret.data.str) {
                            that.pwdShow = true
                        }
                    } else {
                        localStorage.clear()
                        this.$router.push("/login");
                    }

                })
            }
        },
    };
</script>
